import CopyTextToClipboard from "../components/CopyTextToClipboard";

export const email = "frederik.pribyl@gmail.com"
export const my_name = "Frederik Pribyl"
export const address_street = "Memmingerstraße 37"
export const address_city = "89231 Neu-Ulm"
export const address_country = "Germany"


export const imprint_data =
    <section>
        <h1>Impressum (Imprint)</h1>
        <p>
            <div>{my_name}</div>
            <div>{address_street}</div>
            <div>{address_city}</div>
            <div>{address_country}</div>
        </p>
        <p>
            <CopyTextToClipboard text={email} />
        </p>
    </section>
