import { privacy_policy_data } from "../data/PrivacyPolicyData"
import { FooterSpacing } from "./Footer"
import { NavbarSpacing } from "./NavBar"
import Spacing from "./Spacing"

export default function PrivacyPolicy() {
    return (
        <section className={"pageContainer"} style={styles.container}>
            <NavbarSpacing/>
            {privacy_policy_data}
            <Spacing n={8}/>
            <FooterSpacing/>
        </section>
    )
}
const styles = {
    container:{

        // textAlign: "justify"
    }
}