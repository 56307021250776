import { FooterSpacing } from "../Footer"
import { NavbarSpacing } from "../NavBar"
import { useInView } from "framer-motion";
import { useGlobalContext } from "./../GlobalContexts";
import { useRef, useContext, useEffect } from "react";
import LinkButton from "../LinkButton";
import ImageGallery from "./ImageGallery";
import { borderBoxStyle, borderStyle, colorPalette } from "../../styles/GlobalStyles";

export default function ProjectPage({ data }) {
    const titleRef = useRef();
    const titleInView = useInView(titleRef);
    const { setNavbarTitle } = useGlobalContext()

    useEffect(() => {
        setNavbarTitle(titleInView ? "" : data.title);
    }, [titleInView])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const sections = data.sections.map((content, i) => {
        return <section style={styles.sections} key={i}> {content} </section>
    });

    const showLinkButton = (data.link_button !== null && data.link_button.text !== "" && data.link_button.url !== "");

    return (
        <div style={styles.page_container}>
            <NavbarSpacing />
            <div style={styles.header}>
                <div style={styles.date}>{data.date}</div>
                <div style={styles.title} ref={titleRef}>{data.title}</div>
                <div style={styles.subtitle}>{data.subtitle}</div>
            </div>
            <div style={styles.intro_container}>
                <div style={styles.thumbnail_container}>
                    <img style={styles.thumbnail} src={"./../" + data.thumbnail} draggable={false} />
                </div>
                <div style={styles.intro_text}>
                    {data.intro_text}
                </div>
            </div>
            {showLinkButton && <LinkButton url={data.link_button.url}>{data.link_button.text}</LinkButton>}
            {sections}
            {<ImageGallery query={data.image_query} />}
            <FooterSpacing />
        </div>
    )
}

const styles = {
    page_container: {
        position: "relative",
        width: "calc(min(90%, 66rem))",
        top: "0px",
        height: "fit-content",
        display: "flex", flexDirection: "column", gap: "4rem",
        alignItems: "center",
        userSelect: "text"
    },
    header: {
        display: "flex", flexDirection: "column", gap: ".5rem",
        alignItems: "center", justifyContent: "center",
        // marginTop: "2rem",
        // marginBottom: "2rem",
    },
    title: {
        fontFamily: "LexendDeca",
        fontSize: "4rem",
        fontWeight: 400,
        color: "#ffffff"
    },
    subtitle: {
        fontFamily: "LexendDeca",
        fontSize: "1.4rem",
        fontWeight: 300,
        color: "#9caccc"
    },
    date:{
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "1rem",
        color: "#9caccc"
    },
    intro_container: {
        width: "100%",
        display: "flex", flexDirection: "row", gap: "1rem",
        alignItems: "center",
    },
    thumbnail_container: {
        overflow: "hidden",
        flexGrow: 5,
        flexShrink: 1,
        flexBasis: 1,
        borderRadius: ".5rem",
        overflow: "hidden",
        display: "flex", alignItems: "center", justifyContent: "center",
        ...borderStyle,
        borderColor: colorPalette.boxBackground
    },
    thumbnail: {
        width: "100%", height: "100%",
        objectFit: "contain",
        userSelect: "none",
    },
    intro_text: {
        flexGrow: 4,
        flexShrink: 1,
        flexBasis: 1,
        fontSize: ".8rem",
    },
    sections: {
        width: "80%"
    }
}