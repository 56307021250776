import Lorem from "../components/Lorem"
import { Highlight, InlineCopyToClipboard, TextLink } from "../components/InlineTextFormatting"

export const lp_sections = [
    {
        id: "about_me",
        title: "About Me",
        text:
            <section>
                <h1>About Me</h1>
                <p>Hey, I'm Frederik Pribyl</p>
                <p>
                    I am a Game-Production and Management student from Augsburg (Germany)
                    who loves game development, coding and art.
                </p>
                <p>
                    On this portfolio website I showcase some of my projects that I created
                    during my time at university and on my own. Feel free to explore my work
                    and reach out to me for requests or more information.
                </p>
                <p>
                    Click on <TextLink url={"/projects"} noUnderline>Projects</TextLink> in the navbar to see all of my showcased projects.
                     {/* or scroll down to see some of the featured projects. */}
                </p>
                <p>
                    Contact: <InlineCopyToClipboard>frederik.pribyl@gmail.com</InlineCopyToClipboard> or
                    see my <TextLink url={"https://www.linkedin.com/in/frederik-pribyl-99a70a200/"} external>LinkedIn Account</TextLink>
                </p>

                {/* <p>
                    The rest of this page is still work in progress...
                </p> */}
            </section>
    },
    {
        id: "game_development",
        title: "Game Development",
        text:
            <section>
                <h1>Game Development</h1>
                <p>
                    I have a passion for game development and love to learn new skills in the different areas.
                    Coding and game design are my main focus, while I also love creating 3D-Assets
                    and Visual Effects.
                </p>
                <p>
                    My favorite part of game development is creating small details that make
                    a game feel and look good.
                    Since my strenghts are both on the technical and creative side of game development,
                    I can approach a project from multiple angles and bridge the gaps between tech, game design and
                    aesthetics.
                    {/* I think it's valuable to have a sense for these main departments */}
                </p>
                <p>
                    When it comes to <Highlight>Coding</Highlight>, the Unity game engine is my comfort zone.
                    I've worked on several projects during my time as a student, and I believe Unity is the easiest
                    and most universally effective engine. Additionally, I've also explored other engines such
                    as Unreal, Godot, and the web-based 3D rendering engine, three.js.
                </p>
                <p>
                    For <Highlight>3D Art</Highlight> I utilize blender, Substance Designer and -Painter to create
                    3D assets and textures. As a programmer, I prioritize optimization and efficient asset
                    implementation to streamline workflows.
                </p>
                <p>
                    Learning <Highlight>Visual Effects</Highlight> is a natural
                    progression for me as both a programmer and an artist.
                    VFX seamlessly blend technology and aesthetics, significantly
                    enhancing a game's quality with even minimal effort.
                    {/* As programmer and artist learning VFX is a natural
                    step, since they combine tech and aesthetics and significantly increase the quality of a game -
                    even with minimal effort. */}
                </p>
                {/* TODO: add featured projects (3 cards + "show all projects" link below) */}
            </section>
    },
    {
        id: "3d_art",
        title: "3D Art",
        text:
            <section>
                <h1>3D Art</h1>
                <p>
                    Apart from 3D Assets for games (see above) I also create 3D Art generally.
                    I mostly use
                    <TextLink url={"https://www.blender.org/"} external>blender</TextLink>
                    as the basis for a project and other tools like
                    <Highlight>Substance Designer</Highlight> and <Highlight>Substance Painter</Highlight> for
                    texturing.
                </p>
            </section>
    },
    {
        id: "graphic_design",
        title: "Graphic Design",
        text:
            <section>
                <h1>Graphic Design</h1>
                <p>
                    As the co-founder and Lead Graphic Designer of an eSports organization at the 
                    <TextLink url={"https://www.hnu.de/"} autoSpace external>HNU</TextLink>
                    I worked on most of the graphics. This includes everything
                    from concepts to design documents and finished graphics.
                    I created Stream Overlays, Social Media content, infographics
                    and more for several eSport teams and the organization itself.
                    In addition, my job as the Lead Graphic Designer included
                    defining identities for the organization and its teams.
                </p>
            </section>
    },
    {
        id: "web_development",
        title: "Web Development",
        text:
            <section>
                <h1>Web Development</h1>
                <p>
                    During my internship at <TextLink url={"https://immersight.com/"} external autoSpace>immersight</TextLink>
                    I learned javascript, css, three.js and React.js to help out on some of their projects. Quickly after
                    learning React, I started to work on a gamified web-based application for the company. Since
                    immersight is focused on 3D technology, I could learn a lot about 3D rendering in a web context.
                    This new suit of skills perfectly harmonizes with my experience as a 3D Artist and (3D) Game Developer.
                </p>
                <p>
                    Using React.js I also created
                    <TextLink url={"projects/website"} external={false}>this website</TextLink>.
                </p>
            </section>
    }
]


