import { createContext, useContext, useState } from "react";

const GlobalContexts = createContext("");
export const useGlobalContext = () => useContext(GlobalContexts);

export function GlobalContextProvider({ children }) {
    const [navbarTitle, setNavbarTitle] = useState("");
    const [imageStack, setImageStack] = useState([]);
    const [imageIndex, setImageIndex] = useState(-1);

    return (
        <GlobalContexts.Provider value={{
            navbarTitle,
            setNavbarTitle,
            imageStack, setImageStack,
            imageIndex, setImageIndex,
        }}>
            {children}
        </GlobalContexts.Provider>
    )
}