import { useEffect, useRef } from "react"
import { borderStyle } from "../../styles/GlobalStyles.jsx"

export default function LandingPageSection({ title, text, id }) {
    const container = useRef(null);
    useEffect(() => {
        // console.log(text)
        const firstchild = container.current.children[0].children[0];
        firstchild.id = id; // for scroll to
    })


    return (
        <div style={styles.container} ref={container}>
            {text}
            {/* <div style={styles.title}>
                {title}
            </div> */}
            {/* <div style={styles.text}> */}

            {/* </div> */}
        </div>
    )
}

const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        // gap: "20px",
        width: "calc(min(100%, 50rem))",
        userSelect: "text",
        padding: "2rem",
        ...borderStyle,
        backgroundColor: "#282c34",
        // filter: "drop-shadow(0px 0px 4px black)"
        // textAlign: "justify",
    },
}