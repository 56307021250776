import { useContext, useEffect, useRef, useState } from "react";
import { inView, useInView } from "framer-motion";
import { useGlobalContext } from "./GlobalContexts";
import CopyTextToClipboard from "./CopyTextToClipboard";
import { NavbarSpacing } from "./NavBar";
import { email, my_name } from "../data/ImprintData";

export default function LandingScreen({ scrollTo }) {

    const nameTagRef = useRef();
    const nameInView = useInView(nameTagRef);
    const { setNavbarTitle } = useGlobalContext()

    const [hoveringAnchor, setHoveringAnchor] = useState(-1);

    const anchors_data = [
        { title: "Game Developer", link: "game_development", icon: "./icons/joystick.svg" },
        { title: "3D Artist", link: "3d_art", icon: "./icons/cube.svg" },
        { title: "Graphic Designer", link: "graphic_design", icon: "./icons/design.svg" },
    ]

    const anchors_jsx = anchors_data.map((a, i) => {
        return (
            <AnchorLink
                title={a.title}
                link={a.link}
                icon={a.icon}
                scrollTo={scrollTo}
                setHover={setHoveringAnchor}
                id={i}
                isHovering={hoveringAnchor === i}
                key={i}
            />
        )
    })

    useEffect(() => {
        setNavbarTitle(nameInView ? "" : my_name);
    }, [nameInView])


    return (
        <>
        <NavbarSpacing/>
        <div style={styles.landingScreen}>
            <div style={styles.infoContainer}>
                <div style={styles.nameTag} ref={nameTagRef}>
                    {my_name}
                </div>
                <div style={styles.departmentsContainer}>
                    {anchors_jsx}
                </div>
                <CopyTextToClipboard text={email} />
            </div>
        </div>
        </>
    );
}

function AnchorLink({ title, link, icon, scrollTo, setHover, id, isHovering }) {

    return (
        <div
            style={{ ...styles.anchorBox, ...(isHovering ? styles.anchor_hover : null) }}
            onClick={() => scrollTo(link)}
            onMouseOver={() => setHover(id)}
            onMouseLeave={() => setHover(-1)}
        >
            <img src={icon} style={styles.anchor_icon} />
            {title}
        </div>
    )
}



const styles = {
    landingScreen: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        alignItems: "center",
        height: "40rem",
        overflowX: "hidden",
    },
    infoContainer: {
        position: "absolute",
        fontFamily: "LexendDeca",
        textAlign: "center",
        display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center",
        gap: "3rem"
    },
    nameTag: {
        userSelect: "text",
        fontSize: "5rem"
    },
    departmentsContainer: {
        display: "flex", flexDirection: "column", gap: "1rem",
        width: "fit-content"
    },
    anchorBox: {
        fontSize: "1.5rem",
        fontWeight: "300",
        scale: "1",
        transition: "0.125s all ease",
        border: "4px solid #626c80",
        borderRadius: "1rem",
        padding: "1rem 2rem",
        display: "flex", gap: "2rem", alignItems: "center", justifyContent: "flex-start",
        cursor: "pointer",
        backdropFilter: "blur(1px)",
        backgroundColor: "#282c3440"
    },
    anchor_icon: {
        width: "2rem"
    },
    anchor_hover: {
        scale: "1.05",
        backgroundColor: "#282c34"
    }

}