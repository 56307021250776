export default function Spacing({ n, horizontal }) {
    const style_vertical = {
        height: n + "rem",
        alignSelf: "stretch"
    }
    const style_horizontal = {
        width: n + "rem",
        alignSelf: "stretch"
    }

    return (
        <div style={horizontal ? style_horizontal : style_vertical} />
    )
}