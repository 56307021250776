
import { Suspense, useState } from "react"
import { useNavigate } from "react-router-dom";

export default function ProjectCard({ data }) {
    const [hover, setHover] = useState(false);
    const navigate = useNavigate();

    const handleClick = () => {
        if (data.url != null) {
            if (data.url.includes("http")) {
                window.open(data.url, '_blank').focus();
            } else {
                navigate(data.url)
            }
        }
    }

    return (
        <div
            style={{ ...styles.card, ...(hover && styles.hover) }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => handleClick()}
        >
            <div style={styles.thumbnailContainer}>
                {/* <Suspense
                    fallback={<div style={{ ...styles.thumbnail, backgroundColor: "#454c5a"}}>test</div>}
                > */}
                <img src={data.thumbnail} alt={data.title}
                    style={{ ...styles.thumbnail, ...(hover && styles.imgHover) }}
                    draggable={false} />

                {data.date &&
                    <div style={{ ...styles.dateText, ...(hover && styles.dateTextHover) }}>
                        {data.date}
                    </div>
                }
                {/* </Suspense> */}

            </div>

            <div style={styles.textLine}>

                <div style={styles.title}>
                    {data.title}
                </div>

                <div style={styles.type}>
                    {/* {"[" + data.type + "]"} */}
                    {data.type}
                </div>
            </div>


        </div>
    )
}
const cardsize = 32

const styles = {
    card: {
        borderRadius: ".25rem",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.125)",
        transition: "all 0.2s",
        backgroundColor: "#282c34",
        userSelect: "none",
        transformStyle: "preserve-3d",
        zIndex: "1",
        boxSizing: "border-box",
        border: "0.25rem solid #282c34",
        cursor: "pointer",
    },
    hover: {
        transform: "scale(1.025) rotateX(10deg) translateZ(.5rem)",
        zIndex: "2",
        border: "0.25rem solid white"
    },
    textLine: {
        fontFamily: "Inter",
        padding: ".5rem 1rem",
        display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center",
        flexGrow: 1,
        flexShrink: 1,
        // border:"1px solid green"
    },
    title: {
        fontSize: "1.25rem",
        fontFamily: "LexendDeca"
    },
    type: {
        fontSize: ".8rem"
    },
    thumbnailContainer: {
        overflow: "hidden",
        flexGrow: 1,
        // border: "1px solid green"
    },
    thumbnail: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        transition: ".5s all"
    },
    imgHover: {
        transform: "scale(1.05)"
    },
    dateText: {
        position: "absolute",
        top: 0, right: 0,
        fontFamily: "LexendDeca",
        padding: "0.25rem .5rem",
        fontSize: "1rem",
        backgroundColor: "#282c34d0",
        borderRadius: "0rem 0rem 0rem 0.25rem",
        fontWeight: "300",
        transform: "translateY(-100%)",
        transition: "0.2s all"
    },
    dateTextHover: {
        transform: "translate(0%)"
    }
}