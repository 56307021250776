// import "../styles/NavBar.css"
import { createContext, useContext, useEffect, useState } from "react"
import { useGlobalContext } from "./GlobalContexts";
// import ItchioIcon from "../icons/itchio-logo-red.svg"
import ItchioIcon from "../icons/itchio-logo-textless-red.svg"
import LinkedInIcon from "../icons/LinkedIn_icon.svg"

const navbarHeight = "3.5rem";

export default function Navbar() {
    const { navbarTitle } = useGlobalContext()
    const isHome = window.location.pathname == "/"
    const isProjectsList = window.location.pathname == "/projects";
    const isAboutMePage = window.location.pathname == "/about_me";
    const [hoverLogoText, setHoverLogoText] = useState("");

    const handleTitleClick = (() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    });

    const dynamicHomeLinkStyle = {
        color: isHome ? "#ffffff" : "#b9ccf2b0"
    }

    const dynamicProjectsLinkStyle = {
        color: isProjectsList ? "#ffffff" : "#b9ccf2b0"
    }

    return (
        <div style={styles.navbar}>
            <div style={{ ...styles.navbarPart, ...styles.navbarLeft }}>
                <a href="/" style={{ ...styles.navbarLink, ...dynamicHomeLinkStyle }}>
                    Home
                </a>
                <a href="/projects" style={{ ...styles.navbarLink, ...dynamicProjectsLinkStyle }}>
                    Projects
                </a>
            </div>
            <div style={{ ...styles.navbarPart, ...styles.navbarMiddle }} onClick={handleTitleClick}>
                {navbarTitle}
            </div>
            <div style={{ ...styles.navbarPart, ...styles.navbarRight }}>
                <div style={styles.hoverLogoText}>{hoverLogoText}</div>
                <a
                    href="https://www.linkedin.com/in/frederik-pribyl-99a70a200/"
                    target="_blank"
                    rel={"noreferrer"}
                    style={styles.iconLink}
                    onMouseEnter={() => setHoverLogoText("LinkedIn")}
                    onMouseLeave={() => setHoverLogoText("")}
                >
                    <img src={LinkedInIcon} style={styles.icons} />
                </a>
                <a
                    href="https://synthetix.itch.io/"
                    target="_blank"
                    rel={"noreferrer"}
                    style={styles.iconLink}
                    onMouseEnter={() => setHoverLogoText("itch.io")}
                    onMouseLeave={() => setHoverLogoText("")}>
                    <img src={ItchioIcon} style={styles.icons} />
                </a>


            </div>
        </div>
    )
}

export function NavbarSpacing() {
    const spacing = {
        width: "100vw",
        height: navbarHeight
    }
    return <div style={spacing} />
}

const styles = {
    navbar: {
        width: "100vw",
        height: navbarHeight,
        backgroundColor: "#282c34",
        position: "fixed",
        left: 0,
        top: 0,
        zIndex: 10000,
        display: "flex", flexDirection: "row",
        fontFamily: "LexendDeca",
        fontSize: "1.4rem",
        boxShadow: "0px 0px 8px 1px #00000060"
    },
    navbarPart: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "33vw",
        display: "flex",
        alignItems: "center",
        padding: "0px 1%"
    },
    navbarRight: {
        justifyContent: "right",
        display: "flex", gap: "2rem"
    },
    navbarLeft: {
        justifyContent: "left",
        gap: "2vw"
    },
    navbarMiddle: {
        justifyContent: "center"
    },
    navbarLink: {
        textDecoration: "none",
        color: "#ffffff"
    },
    iconLink: {
        height: "fit-content",
        textDecoration: "none",
        display: "flex", justifyContent: "center", alignItems: "center"
    },
    icons: {
        maxHeight: "1.5rem",
        cursor: "pointer"
    },
    hoverLogoText: {
        fontSize: "1rem"
    }
}

