import { NavbarSpacing } from "./NavBar";
import { useInView } from "framer-motion";
import { useGlobalContext } from "./GlobalContexts";
import { useRef, useContext, useEffect } from "react";
import ProjectCard from "./ProjectCard";
import { projectsList } from "../data/ProjectCardsData";
import { FooterSpacing } from "./Footer";
import Spacing from "./Spacing";
import { projectsData } from "../data/ProjectsData";
import LinkButton from "./LinkButton";

export default function Projects() {
    const titleRef = useRef();
    const titleInView = useInView(titleRef);
    const { setNavbarTitle } = useGlobalContext();

    useEffect(() => {
        setNavbarTitle(titleInView ? "" : "Projects");
    }, [titleInView])

    const cards = Object.keys(projectsData).sort((a, b) =>
        projectsData[a].sorting_order - projectsData[b].sorting_order
    ).map((d, i) => {
        if (d === "template") return <></>;
        return <ProjectCard data={projectsData[d]} key={i} />
    });
    // const cards = projectsData
    //     .sort((a, b) => a.sorting_order - b.sorting_order)
    //     .map((d) => {
    //         return <ProjectCard data={d} />
    //     })

    const list_style = { ...styles.list, ...(window.innerWidth > 800 ? styles.list_desktop : styles.list_mobile) }


    return (
        <div style={styles.page}>
            <NavbarSpacing />
            <div style={styles.title} ref={titleRef}>
                Projects
            </div>
            <div style={list_style} className="grid">
                {cards}
            </div>
            <Spacing n={8} />
            <FooterSpacing />
        </div>
    );
}

const styles = {
    page: {
        minHeight: "80vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    title: {
        marginTop: "4rem",
        marginBottom: "4rem",
        fontFamily: "LexendDeca",
        color: "white",
        fontSize: "4rem",
    },
    list: {
        // width: "66vw",
        display: "grid",
        perspective: "80rem",
        // gridTemplateColumns: "24vw 24vw 24vw",
        // gridAutoRows: "16vw",
        // gridTemplateRows: "max-content",
        gridGap: "1rem",
        justifyItems: "stretch"
    },
    // list_desktop: {
    //     gridTemplateColumns: "24vw 24vw 24vw",
    // },
    // list_mobile: {
    //     gridTemplateColumns: "24vw",
    // }
}