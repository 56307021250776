
export const colorPalette = {
    light: "#8c9bb7",
    boxBackground: "#282c34"
}

export const borderStyle = {
    border: ".25rem solid " + colorPalette.light,
    borderRadius: "1rem",
    boxSizing: "border-box",
}

export const borderBoxStyle = {
    ...borderStyle,
    padding: "2rem",
    backgroundColor: colorPalette.boxBackground,
}

