import { useState } from "react"
import open_icon from "./../icons/open_dark.svg"

export default function LinkButton({ children, url, external = null }) {
    const [hover, setHover] = useState(false);

    if (external === null) external = url.includes("https");

    const styles = {
        button: {
            backgroundColor: "#b9ccf2",
            textDecoration: "none",
            color: "#282c34",
            fontWeight: 500,
            fontFamily: "LexendDeca",
            padding: ".75rem 1.5rem",
            fontSize: "1.25rem",
            borderRadius: ".5rem",
            display: "flex", flexDirection: "row", gap: ".5rem",
            alignItems: "center", justifyContent: "center",
            transition: "0.2s transform ease",
            transform: hover ? "scale(1.05)" : "scale(1)",
            userSelect: "none"
        },
        icon: {
            height: "1.25rem",
        }
    }

    return (
        <a
            style={styles.button}
            href={url}
            target={external ? "_blank" : "_self"}
            rel="noopener noreferrer"
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            draggable={false}
        >
            {external === true &&
                <img style={styles.icon} src={open_icon} />
            }
            <span>{children}</span>
        </a>
    )
}
