export const privacy_policy_data =
    <section>
        <h1>Datenschutzerkl&auml;rung</h1>

        <h2>1. Datenschutz auf einen Blick</h2>

        <h3>Allgemeine Hinweise</h3>

        <p>Die folgenden Hinweise geben einen einfachen &Uuml;berblick dar&uuml;ber, was mit Ihren
            personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten
            sind alle Daten, mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen.
            Ausf&uuml;hrliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter
            diesem Text aufgef&uuml;hrten Datenschutzerkl&auml;rung.</p>

        <h3>Datenerfassung auf dieser Website</h3>

        <h4>Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser Website?</h4>
        <p>Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen
            Kontaktdaten k&ouml;nnen Sie dem Abschnitt "Hinweis zur Verantwortlichen
            Stelle" in dieser Datenschutzerkl&auml;rung entnehmen.</p>

        <h4>Wie werden Ihre Daten erfasst?</h4>
        <p>Ihre Daten werden zum einen dadurch erhoben, dass Sie diese mitteilen. Hierbei
            kann es sich z.B. um Daten handeln, die Sie in ein Kontaktformular eingeben.</p>

        <p>Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch
            gewisse IT-Systeme erfasst. Das sind vor allem technische Daten (z.B. Internetbrowser,
            Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch,
            sobald Sie diese Website betreten.</p>

        <h4>Wof&uuml;r werden Ihre Daten genutzt?</h4>
        <p>Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu
            gew&auml;hrleisten. Andere Daten k&ouml;nnen zur Analyse Ihres Nutzerverhaltens verwendet
            werden. Des weiteren dienen Daten v.a. aus dem Kontaktformular, um Kontakt zwischen Ihnen
            und dem Verantwortlichen der Seite herzustellen.</p>

        <h4>Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?</h4>
        <p>Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber Herkunft,
            Empf&auml;nger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten.
            Sie haben au&szlig;erdem ein Recht, die Berichtigung oder L&ouml;schung dieser Daten zu
            verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, k&ouml;nnen
            Sie diese Einwilligung jederzeit f&uuml;r die Zukunft widerrufen. Au&szlig;erdem haben Sie
            das Recht, unter bestimmten Umst&auml;nden die Einschr&auml;nkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der
            zust&auml;ndigen Aufsichtsbeh&ouml;rde zu.</p>

        <p>Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen Sie sich jederzeit an
            mich wenden.</p>

        <h2>2. Hosting</h2>

        <p>Die Inhalte dieser Webseite werden bei folgendem Anbieter gehostet:</p>

        <h3>Hostinger</h3>
        <p>Diese Website wird extern gehostet. Die personenbezogenen Daten, die auf dieser Website erfasst
            werden, werden auf den Servern des Hosters / der Hoster gespeichert. Hierbei kann es sich
            v.a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten,
            Kontaktdaten, Namen, Websitezugriffe und sonstige Daten, die &uuml;ber
            eine Website generiert werden, handeln.</p>

        <p>Das externe Hosting erfolgt zum Zwecke der Vertragserf&uuml;llung gegen&uuml;ber unseren
            potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer
            sicheren, schnellen und effizienten Bereitstellung unseres Online-Angebots durch einen
            professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO). Sofern eine entsprechende
            Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage
            von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die
            Speicherung von Cookies oder den Zugriff auf Informationen im Endger&auml;t des Nutzers
            (z.B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit
            widerrufbar.</p>

        <p>Der Hoster wird bzw. werden Ihre Daten nur insoweit verarbeiten, wie dies zur
            Erf&uuml;llung seiner Leistungspflichten erforderlich ist und unsere Weisungen in
            Bezug auf diese Daten befolgen.</p>

        <p>Folgender Hoster wird eingesetzt:</p>
        <p>HOSTINGER INTERNATIONAL LIMITED<br />
            61 Lordou Vironos Street Lumiel Building, 4th floor CYP-6023 Larnaca, Zypern</p>

        <h2>3. Allgemeine Hinweise und Pflichtinformationen</h2>

        <h3>Datenschutz</h3>

        <p>Die Betreiber dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen Daten sehr ernst.
            Ihre personenbezogenen Daten werden vertraulich und entsprechend den gesetzlichen
            Datenschutzvorschriften sowie dieser Datenschutzerkl&auml;rung behandelt.</p>

        <p>Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben.
            Personenbezogene Daten sind Daten, mit denen Sie pers&ouml;nlich identifiziert werden
            k&ouml;nnen. Die vorliegende Datenschutzerkl&auml;rung erl&auml;utert, welche Daten
            erhoben werden und wof&uuml;r diese genutzt werden. Sie erl&auml;utert auch, wie und zu welchem Zweck das
            geschieht.</p>

        <p>Es wird darauf hingewiesen, dass die Daten&uuml;bertragung im Internet (z.B. bei der
            Kommunikation per E-Mail) Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser
            Schutz der Daten vor dem Zugriff durch Dritte ist nicht m&ouml;glich.</p>

        <h3>Hinweis zur verantwortlichen Stelle</h3>

        <p>Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf dieser Website ist:</p>
        <p>Frederik Pribyl<br />
            Memmingerstra&szlig;e 37<br />
            89231 Neu-Ulm</p>
        <p>
            {/* Telefon: &#91;Telefonnummer der verantwortlichen Stelle&#93;<br /> */}
            E-Mail: frederik.pribyl@gmail.com</p>
        <p>Verantwortliche Stelle ist die nat&uuml;rliche oder juristische Person, die allein oder
            gemeinsam mit anderen  &uuml;ber die Zwecke und Mittel der Verarbeitung von personenbezogenen
            Daten (z.B. Namen, E-Mail-Adressen o.&auml;.) entscheidet.</p>

        <h3>Speicherdauer</h3>

        <p>Soweit innerhalb dieser Datenschutzerkl&auml;rung keine speziellere Speicherdauer genannt
            wurde, verbleiben Ihre personenbezogenen Daten, bis der Zweck f&uuml;r die
            Datenverarbeitung entf&auml;llt. Wenn Sie ein berechtigtes L&ouml;schersuchen geltend
            machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten
            gel&ouml;scht, sofern  keine anderen rechtlich zul&auml;ssigen Gr&uuml;nde f&uuml;r
            die Speicherung Ihrer personenbezogenen Daten vorliegen (z.B. steuer- oder
            handelsrechtliche Aufbewahrungsfristen); im letztgenannten
            Fall erfolgt die L&ouml;schung nach Fortfall dieser Gr&uuml;nde.</p>

        <h3>Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website</h3>
        <p>Sofern Sie in die Datenverarbeitung eingewilligt haben, werden Ihre personenbezogenen Daten auf
            Grundlage
            von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO verarbeitet, sofern besondere Datenkategorien nach Art. 9
            Abs. 1
            DSGVO verarbeitet werden. Im Falle einer ausdr&uuml;cklichen Einwilligung in die &uuml;bertragung
            personenbezogener
            Daten in Drittstaaten erfolgt die Datenverarbeitung au&szlig;erdem auf Grundlage von Art. 49 Abs. 1 lit. a
            DSGVO.
            Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in Ihr Endger&auml;t
            (z.B.
            via
            Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung zus&auml;tzlich auf Grundlage von
            § 25
            Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur Vertragserf&uuml;llung oder
            zur
            Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich, werden Ihre Daten auf Grundlage
            des
            Art. 6
            Abs. 1 lit. b DSGVO verarbeitet. Des Weiteren werden Ihre Daten verarbeitet, sofern diese zur Erf&uuml;llung einer
            rechtlichen
            Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann
            ferner
            auf
            Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO erfolgen. &uuml;ber die jeweils im
            Einzelfall einschl&auml;gigen Rechtsgrundlagen wird in den folgenden Abs&auml;tzen dieser
            Datenschutzerkl&auml;rung
            informiert.</p>
        <h3>Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten</h3>
        <p>Es werden unter anderem Tools von Unternehmen mit Sitz in den USA oder sonstigen datenschutzrechtlich
            nicht
            sicheren Drittstaaten verwendet. Wenn diese Tools aktiv sind, k&ouml;nnen Ihre personenbezogene Daten in diese
            Drittstaaten
            &uuml;bertragen und dort verarbeitet werden. Es wird darauf hingewiesen, dass in diesen L&auml;ndern kein mit der
            EU
            vergleichbares Datenschutzniveau garantiert werden kann. Beispielsweise sind US-Unternehmen dazu
            verpflichtet,
            personenbezogene Daten an Sicherheitsbeh&ouml;rden herauszugeben, ohne dass Sie als Betroffener hiergegen
            gerichtlich vorgehen k&ouml;nnten. Es kann daher nicht ausgeschlossen werden, dass US-Beh&ouml;rden
            (z.B.
            Geheimdienste) Ihre auf US-Servern befindlichen Daten zu &uuml;berwachungszwecken verarbeiten, auswerten und
            dauerhaft speichern. Ich habe auf diese Verarbeitungst&auml;tigkeiten keinen Einfluss.</p>
        <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
        <p>Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer ausdr&uuml;cklichen Einwilligung m&ouml;glich. Sie
            k&ouml;nnen eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtm&auml;&szlig;igkeit der bis
            zum
            Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unber&uuml;hrt.</p>
        <h3>Widerspruchsrecht gegen die Datenerhebung in besonderen F&auml;llen sowie gegen Direktwerbung (Art. 21
            DSGVO)
        </h3>
        <p>WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT
            DAS
            RECHT, AUS GR&uuml;NDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER
            PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH F&uuml;R EIN AUF DIESE BESTIMMUNGEN
            GEST&uuml;TZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER
            DATENSCHUTZERKL&auml;RUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN IHRE BETROFFENEN PERSONENBEZOGENEN
            DATEN VON MIR NICHT MEHR VERARBEITET, ES SEI DENN, ES K&ouml;NNEN ZWINGENDE SCHUTZW&uuml;RDIGE GR&uuml;NDE F&uuml;R DIE VERARBEITUNG
            NACHWEISEN WERDEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN &uuml;BERWIEGEN ODER DIE VERARBEITUNG DIENT DER
            GELTENDMACHUNG, AUS&uuml;BUNG ODER VERTEIDIGUNG VON RECHTSANSPR&uuml;CHEN (WIDERSPRUCH NACH ART. 21 ABS. 1
            DSGVO).
        </p>
        <p>WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT,
            JEDERZEIT
            WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG
            EINZULEGEN; DIES GILT AUCH F&uuml;R DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT.
            WENN
            SIE
            WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG
            VERWENDET
            (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).</p>
        <h3>Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde</h3>
        <p>Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer
            Aufsichtsbeh&ouml;rde, insbesondere in dem Mitgliedstaat ihres gew&ouml;hnlichen Aufenthalts, ihres
            Arbeitsplatzes
            oder des Orts des mutma&szlig;lichen Versto&szlig;es zu. Das Beschwerderecht besteht unbeschadet
            anderweitiger
            verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.</p>
        <h3>Recht auf Daten&uuml;bertragbarkeit</h3>
        <p>Sie haben das Recht, Daten, die auf Grundlage Ihrer Einwilligung oder in Erf&uuml;llung eines Vertrags
            automatisiert verarbeitet werden, an sich oder an einen Dritten in einem g&auml;ngigen, maschinenlesbaren Format
            aush&auml;ndigen zu lassen. Sofern Sie die direkte &uuml;bertragung der Daten an einen anderen
            Verantwortlichen
            verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>
        <h3>Auskunft, Berichtigung und L&ouml;schung</h3>
        <p>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft
            &uuml;ber
            Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empf&auml;nger und den Zweck der
            Datenverarbeitung
            und ggf. ein Recht auf Berichtigung oder L&ouml;schung dieser Daten. Hierzu sowie zu weiteren Fragen zum
            Thema
            personenbezogene Daten k&ouml;nnen Sie sich jederzeit an mich wenden.</p>
        <h3>Recht auf Einschr&auml;nkung der Verarbeitung</h3>
        <p>Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            Hierzu
            k&ouml;nnen Sie sich jederzeit an mich wenden. Das Recht auf Einschr&auml;nkung der Verarbeitung besteht in
            folgenden
            F&auml;llen:</p>
        <ul>
            <li>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, ben&ouml;tige ich
                in der Regel Zeit, um dies zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer der Pr&uuml;fung haben Sie das
                Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
            <li>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtm&auml;&szlig;ig geschah/geschieht,
                k&ouml;nnen Sie statt der L&ouml;schung die Einschr&auml;nkung der Datenverarbeitung verlangen.</li>
            <li>Wenn Ihre personenbezogenen Daten nicht mehr ben&ouml;tigt werden, Sie sie jedoch zur Aus&uuml;bung,
                Verteidigung oder Geltendmachung von Rechtsanspr&uuml;chen ben&ouml;tigen, haben Sie das Recht, statt der
                L&ouml;schung die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
            <li>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abw&auml;gung zwischen
                Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen &uuml;berwiegen,
                haben Sie das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
        </ul>
        <p>Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschr&auml;nkt haben, d&uuml;rfen diese Daten
            - von ihrer Speicherung abgesehen - nur mit Ihrer Einwilligung oder zur Geltendmachung, Aus&uuml;bung oder
            Verteidigung von Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen nat&uuml;rlichen oder
            juristischen Person oder aus Gr&uuml;nden eines wichtigen &ouml;ffentlichen Interesses der Europ&auml;ischen Union oder
            eines Mitgliedstaats verarbeitet werden.</p>
        <h3>SSL- bzw. TLS-Verschl&uuml;sselung</h3>
        <p>Diese Seite nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der &uuml;bertragung vertraulicher Inhalte, wie
            zum
            Beispiel Bestellungen oder Anfragen, die Sie an den Seitenbetreiber senden, eine SSL- bzw.
            TLS-Verschl&uuml;sselung. Eine verschl&uuml;sselte Verbindung erkennen Sie daran, dass die Adresszeile des
            Browsers
            von "http://" auf "https://" wechselt und an dem Schloss-Symbol in Ihrer
            Browserzeile.
        </p>
        <p>Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist, k&ouml;nnen die Daten, die Sie an uns
            &uuml;bermitteln,
            nicht von Dritten mitgelesen werden.</p>
        <h2>4. Datenerfassung auf dieser Website</h2>
        <h3>Kontaktformular</h3>
        <p>Wenn Sie mir per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular
            inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und f&uuml;r den Fall von
            Anschlussfragen gespeichert. Diese Daten werden nicht ohne Ihre Einwilligung weitergegeben.</p>
        <p>Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit
            der Erf&uuml;llung eines Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen
            erforderlich ist. In allen &uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem berechtigten
            Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
            Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.</p>
        <p>Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur L&ouml;schung
            auffordern,
            Ihre Einwilligung zur Speicherung widerrufen oder der Zweck f&uuml;r die Datenspeicherung entf&auml;llt
            (z.B.
            nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen - insbesondere
            Aufbewahrungsfristen - bleiben unber&uuml;hrt.</p>
        <h3>Anfrage per E-Mail, Telefon oder Telefax</h3>
        <p>Wenn Sie mich per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus
            hervorgehenden
            personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens gespeichert und
            verarbeitet. Diese Daten werden nicht ohne Ihre Einwilligung weitergegeben.</p>
        <p>Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit
            der
            Erf&uuml;llung eines Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen
            erforderlich ist. In allen &uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem berechtigten
            Interesse
            an
            der effektiven Bearbeitung der an mich gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
            Einwilligung
            (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.</p>
        <p>Die von Ihnen per Kontaktanfragen &uuml;bersandten Daten verbleiben, bis Sie uns zur
            L&ouml;schung
            auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck f&uuml;r die Datenspeicherung
            entf&auml;llt
            (z.B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen -
            insbesondere gesetzliche Aufbewahrungsfristen - bleiben unber&uuml;hrt.</p>
        <h2>5. Soziale Medien</h2>
        <h3>Instagram</h3>
        <p>Auf dieser Website sind Funktionen des Dienstes Instagram eingebunden. Diese Funktionen werden angeboten
            durch
            die
            Meta Platforms Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland.</p>
        <p>Wenn das Social-Media-Element aktiv ist, wird eine direkte Verbindung zwischen Ihrem Endger&auml;t und dem
            Instagram-Server hergestellt. Instagram erh&auml;lt dadurch Informationen &uuml;ber den Besuch dieser
            Website
            durch
            Sie.</p>
        <p>Wenn Sie in Ihrem Instagram-Account eingeloggt sind, k&ouml;nnen Sie durch Anklicken des Instagram-Buttons
            die Inhalte dieser Website mit Ihrem Instagram-Profil verlinken. Dadurch kann Instagram den Besuch dieser
            Website Ihrem Benutzerkonto zuordnen. Es wird darauf hingewiesen, dass ich als Anbieter der Seiten keine Kenntnis vom Inhalt
            der &uuml;bermittelten Daten sowie deren Nutzung durch Instagram erhalte.</p>
        <p>Soweit eine Einwilligung (Consent) eingeholt wurde, erfolgt der Einsatz des o.g. Dienstes auf Grundlage
            von Art. 6 Abs. 1 lit. a DSGVO und § 25 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Soweit keine
            Einwilligung eingeholt wurde, erfolgt die Verwendung des Dienstes auf Grundlage unseres berechtigten
            Interesses
            an einer m&ouml;glichst umfassenden Sichtbarkeit in den Sozialen Medien.</p>
        <p>Soweit mit Hilfe des hier beschriebenen Tools personenbezogene Daten auf unserer Website erfasst und an
            Facebook bzw. Instagram weitergeleitet werden, sind wir und die Meta Platforms Ireland Limited, 4 Grand Canal Square,
            Grand Canal Harbour, Dublin 2, Irland gemeinsam f&uuml;r diese Datenverarbeitung verantwortlich (Art. 26 DSGVO). Die
            gemeinsame Verantwortlichkeit beschr&auml;nkt sich dabei ausschlie&szlig;lich auf die Erfassung der Daten und deren
            Weitergabe an Facebook bzw. Instagram. Die nach der Weiterleitung erfolgende Verarbeitung durch Facebook bzw. Instagram
            ist nicht Teil der gemeinsamen Verantwortung. Die uns gemeinsam obliegenden Verpflichtungen wurden in einer
            Vereinbarung
            &uuml;ber gemeinsame Verarbeitung festgehalten. Den Wortlaut der Vereinbarung finden Sie unter: <a
                href="https://www.facebook.com/legal/controller_addendum" target="_blank"
                rel="noopener noreferrer">https://www.facebook.com/legal/controller_addendum</a>. Laut dieser
            Vereinbarung
            sind wir f&uuml;r die Erteilung der Datenschutzinformationen beim Einsatz des Facebook- bzw. Instagram-Tools und
            f&uuml;r die datenschutzrechtlich sichere Implementierung des Tools auf unserer Website verantwortlich. F&uuml;r die
            Datensicherheit der Facebook bzw. Instagram-Produkte ist Facebook verantwortlich. Betroffenenrechte
            (z.B. Auskunftsersuchen) hinsichtlich der bei Facebook bzw. Instagram verarbeiteten Daten k&ouml;nnen Sie direkt
            bei Facebook geltend machen. Wenn Sie die Betroffenenrechte bei uns geltend machen, sind wir verpflichtet, diese
            an Facebook weiterzuleiten.</p>
        <p>Die Daten&uuml;bertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gest&uuml;tzt.
            Details
            finden Sie hier: <a href="https://www.facebook.com/legal/EU_data_transfer_addendum" target="_blank"
                rel="noopener noreferrer">https://www.facebook.com/legal/EU_data_transfer_addendum</a>, <a
                    href="https://help.instagram.com/519522125107875" target="_blank"
                    rel="noopener noreferrer">https://help.instagram.com/519522125107875</a> und <a
                        href="https://de-de.facebook.com/help/566994660333381" target="_blank"
                        rel="noopener noreferrer">https://de-de.facebook.com/help/566994660333381</a>.</p>
        <p>Weitere Informationen hierzu finden Sie in der Datenschutzerkl&auml;rung von Instagram: <a
            href="https://instagram.com/about/legal/privacy/" target="_blank"
            rel="noopener noreferrer">https://instagram.com/about/legal/privacy/</a>.</p>
        <h2>6. Plugins und Tools</h2>
        <h3>YouTube</h3>
        <p>Diese Website bindet Videos der Website YouTube ein. Betreiber der Website ist die Google Ireland Limited
            ("Google"), Gordon House, Barrow Street, Dublin 4, Irland.</p>
        <p>Wenn Sie eine unserer Webseiten besuchen, auf denen YouTube eingebunden ist, wird eine Verbindung zu den
            Servern
            von
            YouTube hergestellt. Dabei wird dem YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht haben.</p>
        <p>Des Weiteren kann YouTube verschiedene Cookies auf Ihrem Endger&auml;t speichern oder vergleichbare
            Technologien
            zur
            Wiedererkennung verwenden (z.B. Device-Fingerprinting). Auf diese Weise kann YouTube Informationen
            &uuml;ber
            Besucher dieser Website erhalten. Diese Informationen werden u.a. verwendet, um Videostatistiken zu
            erfassen,
            die Anwenderfreundlichkeit zu verbessern und Betrugsversuchen vorzubeugen.</p>
        <p>Wenn Sie in Ihrem YouTube-Account eingeloggt sind, erm&ouml;glichen Sie YouTube, Ihr Surfverhalten direkt
            Ihrem
            pers&ouml;nlichen Profil zuzuordnen. Dies k&ouml;nnen Sie verhindern, indem Sie sich aus Ihrem
            YouTube-Account
            ausloggen.</p>
        <p>Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote. Dies
            stellt
            ein
            berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung
            abgefragt
            wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und §
            25
            Abs.
            1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im
            Endger&auml;t
            des
            Nutzers (z.B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit
            widerrufbar.
        </p>
        <p>Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerkl&auml;rung von YouTube
            unter:
            <a href="https://policies.google.com/privacy?hl=de" target="_blank"
                rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a>.
        </p>
        <h3>Google Fonts (lokales Hosting)</h3>
        <p>Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Google Fonts, die von Google
            bereitgestellt werden. Die Google Fonts sind lokal installiert. Eine Verbindung zu Servern von Google findet
            dabei
            nicht statt.</p>
        <p>Weitere Informationen zu Google Fonts finden Sie unter <a href="https://developers.google.com/fonts/faq"
            target="_blank" rel="noopener noreferrer">https://developers.google.com/fonts/faq</a> und in der
            Datenschutzerkl&auml;rung von Google: <a href="https://policies.google.com/privacy?hl=de" target="_blank"
                rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a>.</p>
        <p>Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>

    </section>