import { useState } from "react";
import { borderStyle, colorPalette } from "../../styles/GlobalStyles";
import { useGlobalContext } from "../GlobalContexts";

export default function ImageGallery({ query }) {
    const { imageStack, setImageStack, imageIndex, setImageIndex } = useGlobalContext()

    if (query === null || query === undefined) return <></>
    const indices = Array.from({ length: query.max_index + 1 }, (_, index) => index);

    const sources = indices.map((i) => {
        let id_string = i.toString().padStart(3, "0")
        return query.public_path + query.prefix + id_string + query.suffix
    });

    const handleClickImage = (id) => {
        setImageIndex(id);
        setImageStack(sources);
        // const newStack = 
    }

    const images = sources.map((src, i) => {
        return <ImageElement src={src} key={i} id={i} onClick={handleClickImage} />
    });

    return <>
        <div style={styles.container}>
            <div style={styles.grid}>
                {images}
            </div>
            <div style={styles.hint}>{"click on an image to view larger"}</div>
        </div>

    </>
}

function ImageElement({ src, onClick, id }) {
    const [show, setShow] = useState(true);
    const [hover, setHover] = useState(false);

    if (show === false) return <></>

    return (
        <img
            src={src}
            style={{ ...styles.image, ...(hover && styles.imageHover) }}
            onError={() => setShow(false)}
            onLoad={() => setShow(true)}
            onClick={() => onClick(id)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        />

    )

}

const styles = {
    container: {

        width: "100%",
        display: "flex", flexDirection: "column",
        gap: "1rem",

        padding: "1rem",
        backgroundColor: "#282c34",
        userSelect: "none",
        ...borderStyle
    },
    grid: {
        display: "grid",
        gridTemplateColumns: "6fr 6fr 6fr",
        gridGap: ".5rem",
    },
    image: {
        width: "100%",
        cursor: "zoom-in",
        transition: "0.2s all"
    },
    imageHover: {
        transform: "scale(1.025)",
        zIndex: "2"
    },
    hint: {
        textAlign: "center",
        fontSize: "1rem",
        color: colorPalette.light,
        userSelect: "none"
    }
}