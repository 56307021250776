const footerHeight = "5rem"

export default function Footer() {
    return (
        <div style={styles.footer}>
            <a style={styles.links} href="/privacy_policy">Datenschutz<br/>Privacy Policy</a>
            {/* <a style={styles.links} href="/imprint">Contact</a> */}
            <a style={styles.links} href="/imprint">Impressum<br/>Imprint</a>
        </div>
    )
}

export function FooterSpacing() {
    return (
        <div style={styles.spacing}/>
    )
}

const styles = {
    footer: {
        marginTop: "-" + footerHeight,
        height: footerHeight,
        width: "80vw",
        display: "flex", flexDirection: "row",
        justifyContent: "space-around", alignItems: "center",
        fontFamily: "LexendDeca",
        fontWeight: "400",
        fontSize: ".8rem",
        borderTop: "1px solid #b9ccf2b0",
        backgroundColor: "linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.5))",
        zIndex: "99999"
        // transform: "translate(0,-100%)"
    },
    links: {
        textDecoration: "none",
        color: "#b9ccf2b0",
        display:"flex", alignItems:"center", justifyContent:"center",
        flexDirection:"column"
    },
    spacing:{
        height: footerHeight
    }
}