export default function MyWebsite() {
    const styles = {
        container: {
            display: "flex", flexDirection: "column", gap: "rem",
            alignItems: "center"
        },
        object: {
            width: "100%",
            height: "100%",
            borderRadius: ".25rem",
        },
        embed_container: {
            width: "75vw",
            height: "75vh",
            position: "relative",
            backgroundColor: "#454c5a",
            padding: "1rem",
            borderRadius: ".5rem",
            boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.125)",
            overflow: "hidden"
        }
    }
    return (
        <div style={styles.container}>
            <h1>Have a look</h1>
            <p>
                Here is this website again - click inside to scroll.
            </p>
            <p>
                (It might be buggy, better use the original.)
            </p>
            <br />
            <div style={styles.embed_container}>
                <object style={styles.object} data={"./index.html"} />
            </div>
        </div>
    )
}