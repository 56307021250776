import { useState } from "react";
import open_icon from "../icons/open_light.svg";
import copy_icon from "../icons/copy_light.svg";
import check_icon from "../icons/done_light.svg"

export function Highlight({ children, strength = 1, autoSpace = false }) {
    const style = {
        color: "#dedfff",
        fontWeight: (400 + strength * 100),
    }
    return <>
        {" "}
        <span style={style}>{children}</span>
        {autoSpace && " "}
    </>
}

export function TextLink({ children, url, external = null, showExternalIcon = true, autoSpace = false, noUnderline = false }) {
    const styles = {
        text: {
            color: "#dedfff",
            textDecoration: noUnderline?"none":"underline"
        },
        icon: {
            height: "1.125rem",
            marginLeft: ".2rem",
            userSelect: "none",
        },
        space: {
            textDecoration: "none"
        }
    }

    if (external === null) external = url.includes("https");
    return <>
        {" "}
        <a
            style={styles.text}
            href={url}
            target={external ? "_blank" : "_self"}
            rel="noreferrer noopener"
            draggable={false}
        >
            {children}
            {(external && showExternalIcon) &&
                <>
                    <img
                        src={open_icon}
                        style={styles.icon}
                        draggable={false}
                    />
                </>
            }
        </a>
        {autoSpace && " "}
    </>
}

export function InlineCopyToClipboard({ children, showIcon = true, autoSpace = false }) {
    const styles = {
        text: {
            color: "#dedfff",
            textDecoration: "underline",
            cursor: "pointer"
        },
        icon: {
            height: "1.125rem",
            marginLeft: ".2rem",
            userSelect: "none"
        }
    }

    const [copiedToClipboard, setCopiedToClipboard] = useState(false);

    const handleClick = () => {
        navigator.clipboard.writeText(children)
        setCopiedToClipboard(true)
        setTimeout(() => {
            setCopiedToClipboard(false)
        }, 1000)
    }

    return <>
        {" "}
        <span onClick={handleClick} style={styles.text}>
            {children}
            {
                showIcon &&
                <img
                    src={copiedToClipboard ? check_icon : copy_icon}
                    style={styles.icon}
                    draggable={false}
                />
            }
        </span>
        {autoSpace && " "}
    </>
}