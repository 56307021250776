import { imprint_data } from "../data/ImprintData"
import { NavbarSpacing } from "./NavBar"
import Spacing from "./Spacing"

export default function Imprint() {
    return (
        <div style={styles.container}>
            {imprint_data}
        </div>
    )
}

const styles = {
    container:{
        height: "100vh",
        display: "flex", alignItems: "center", justifyContent: "center"
    }
}