
import LandingScreen from "./LandingScreen"
import LandingPageSection from "./LandingPage/LandingPageSection"
import { lp_sections } from "../data/LandingPageSectionsData"
import { useState } from "react"
import { NavbarSpacing } from "./NavBar"
import Spacing from "./Spacing"
import Button from "./Button"
import LinkButton from "./LinkButton"
import { transform } from "framer-motion"

export default function LandingPage() {

    const sections = lp_sections.map((data, i) => {
        return <LandingPageSection id={data.id} title={data.title} text={data.text} key={i} />
    })

    const scrollTo = (section_id) => {
        const section = document.getElementById(section_id);
        if (section === null) return
        const newScrollTop = section.offsetTop - (window.innerHeight * .5) + (section.getBoundingClientRect().height * .5)
        window.scrollTo({ top: newScrollTop, left: 0, behavior: "smooth" })
    }

    return (
        <div style={styles.landingPage}>
            <LandingScreen scrollTo={scrollTo} />
            {/* <a href="#2">test</a> */}
            <section className="pageContainer" style={styles.sectionsContainer}>
                {sections}
            </section>
            <LinkButton url={"/projects"}>see all projects</LinkButton>
            {/* <Button text={"see all projects"}/> */}
            {/* <LandingPageCanvas/> */}
            {/* <UpButton /> */}
            <Spacing n={8} />
        </div>
    )
}

// function UpButton() {
//     const handleClick = () => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }
//     return (
//         <div style={styles.upButton} onClick={handleClick}>
//             ↑
//         </div>
//     )
// }

const styles = {
    landingPage: {
        // width: "100vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflowX: "hidden",
        gap: "64px"
    },
    sectionsContainer: {
        gap: "3rem",
        // transform:"translate(0, -8vh)"
    },
    upButton: {
        position: "fixed", right: 0, bottom: 0,
        display: "flex", alignItems: "center", justifyContent: "center",
        width: "80px", height: "80px",
        transform: "translate(-32px, -32px)",
        backgroundColor: "#282c34",
        boxShadow: "0px 0px 8px 1px #00000060",
        borderRadius: "128px",
        fontSize: "2rem",
        fontWeight: "400",
        zIndex: "10000"
    },
}

