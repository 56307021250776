import { projectsData } from "../data/ProjectsData";
import { borderBoxStyle, borderStyle, colorPalette } from "../styles/GlobalStyles";
import { useGlobalContext } from "./GlobalContexts";
import ArrowIcon from "../icons/arrow_dark.svg"
import CloseIcon from "../icons/close_light.svg"
import { useState } from "react";

export default function ImageViewer() {
    const { imageStack, setImageStack, imageIndex, setImageIndex } = useGlobalContext();

    const buttonsDisabled = [imageIndex <= 0, imageIndex >= imageStack.length - 1]

    const handleButtonClick = (e, direction) => {
        handleNotClickAway(e);
        console.log(direction)
        let newIndex = imageIndex + direction
        if (newIndex < 0) newIndex = 0;
        if (newIndex >= imageStack.length) newIndex = imageStack.length - 1;
        setImageIndex(newIndex)
        // -3 -2 -1 [0 1 2 3 4] 5 6 7
    }

    const handleClose = () => {
        setImageIndex(-1);
        setImageStack([]);
    }

    const handleNotClickAway = (e) => {
        e.stopPropagation();
    }

    const currentIndexString = (imageIndex + 1) + "/" + (imageStack.length)

    if (imageIndex === -1 || imageStack === null || imageStack === undefined || imageStack.length === 0) return <></>

    return (
        <div style={styles.curtain} onClick={handleClose}>
            <Button direction={-1} onClick={handleButtonClick} disabled={buttonsDisabled[0]} />
            <div style={styles.window} onClick={handleNotClickAway} >
                <TopBar onClickCloseButton={handleClose} />
                <img
                    src={imageStack[imageIndex]}
                    style={styles.image}
                    draggable={false}
                />
                <div style={styles.indexText}>{currentIndexString}</div>
            </div>
            <Button direction={1} onClick={handleButtonClick} disabled={buttonsDisabled[1]} />
            {/* <div style={styles.container}>
            </div> */}

        </div>
    );
}

function Button({ direction, onClick, disabled = false }) {
    const [hover, setHover] = useState(false);

    const rotationStyle = {
        transform: "rotate(" + (direction === -1 ? 180 : 0) + "deg)"
    }

    const buttonCombinedStyle = {
        ...styles.buttons,
        ...(hover && !disabled ? styles.buttonsHover : null),
        // ...{ visibility: disabled ? "hidden" : "visible" },
        ...{ opacity: disabled ? "0.25" : "1" },
        ...{ cursor: disabled ? "default" : "pointer" }
    }

    return (
        <div
            style={buttonCombinedStyle}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={(e) => onClick(e, direction)}
        >
            <img
                src={ArrowIcon}
                style={{ ...styles.buttonsArrow, ...rotationStyle }}
                draggable={false}
            />
        </div>
    )
}

function TopBar({ onClickCloseButton }) {
    const [hoverCloseButton, setHoverCloseButton] = useState(false);
    return (
        <div style={styles.topBar}>
            {/* <div style={styles.closeButton}>
                
            </div> */}
            <img
                src={CloseIcon}
                style={{ ...styles.closeButton, ...{ transform: (hoverCloseButton ? "scale(1.1)" : "scale(1)") } }}
                onMouseEnter={() => setHoverCloseButton(true)}
                onMouseLeave={() => setHoverCloseButton(false)}
                onClick={onClickCloseButton}
                draggable={false}
            />
        </div>
    )
}

const styles = {
    curtain: {
        width: "100vw",
        height: "100vh",
        backgroundColor: "#00000050",
        position: "fixed",
        zIndex: "100000",
        backdropFilter: "blur(2px)",
        display: "flex", alignItems: "center", justifyContent: "center",
        gap: "5vw",
        cursor: "zoom-out"
    },
    buttons: {
        display: "flex", alignItems: "center", justifyContent: "center",
        padding: "1rem",
        backgroundColor: colorPalette.light,
        borderRadius: "1rem",
        transition: "scale 0.2s, visibility: 0s",
        // cursor: "pointer"
        // overflow: "hidden"
    },
    buttonsArrow: {
        width: "4vw",
        height: "4vw",
    },
    window: {
        ...borderBoxStyle,
        padding: "1rem",
        boxSizing: "border-box",
        filter: "drop-shadow(0px 0px 0.5rem black)",
        display: "flex", flexDirection: "column", alignItems: "center",
        fontFamily: "LexendDeca",
        gap: "1vh",
        cursor: "default"
        // paddingTop: "1rem"
        // width: "50vw", height: "50vh",
        // objectFit: "cover"
        // width: "fit-content",
    },
    image: {
        // width: "100%",
        // height: "100%",
        // marginBottom: "2vh",
        // marginTop: "1rem",
        maxWidth: "60vw",
        maxHeight: "80vh"
    },
    buttonsHover: {
        transform: "scale(1.05)"
    },
    topBar: {
        height: "2.5rem", width: "100%",
        display: "flex", flexDirection: "row", justifyContent: "flex-end",
        // border:"1px solid red"
    },
    closeButton: {
        width: "2.25rem",
        height: "2.25rem",
        transform: "translateX(25%)",
        transition: "0.2s all",
        cursor: "pointer"
        // ...borderStyle,
        // borderRadius: "0.5rem",
    },
    indexText: {
        fontSize: "1.5rem",
        // fontFamily: "Inter",
        color: colorPalette.light,
        margin: ".25rem"
    }
}