import { borderBoxStyle } from "../styles/GlobalStyles"
import ArrowIcon from "../icons/arrow_light.svg"
import { useEffect, useState } from "react"

export default function UpButton() {
    const [hover, setHover] = useState(false);
    const [show, setShow] = useState(true);

    const handleClick = () => { window.scrollTo({ top: 0, behavior: "smooth" }) }

    // useEffect(() => {
    //     setShow(window.screenTop > 0)
    //     console.log(window.scrollY)
    // }, [window.scrollY])

    const handleScroll = (e) => {
        console.log(window.scrollY)
        setShow(window.scrollY > 50)
    }

    useEffect(() => {
        // clean up code
        window.removeEventListener('scroll', handleScroll);
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div
            style={{ ...styles.upButton, ...((hover && show) && styles.hoverButton), ...(!show && styles.hidden) }}
            onClick={handleClick}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onScroll={handleScroll}
        >
            <img src={ArrowIcon} style={styles.arrow} />
        </div>
    )
}

const styles = {
    upButton: {
        ...borderBoxStyle,
        position: "fixed", right: "2rem", bottom: "2rem",
        display: "flex", alignItems: "center", justifyContent: "center",
        // width: "80px", height: "80px",
        padding: "1rem",
        // transform: "translate(-2rem, -2rem)",
        boxShadow: "0px 0px 8px 1px #00000060",
        zIndex: "10000",
        cursor: "pointer",
        transform: "scale(1)",
        transition: "0.2s all"
    },
    arrow: {
        height: "2rem",
        transform: "rotate(270deg)"
    },
    hoverButton: {
        transform: "scale(1.05)"
    },
    hidden: {
        transform: "scale(0)"
    }
}