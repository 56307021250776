import { useState } from "react";

export default function CopyTextToClipboard({ text }) {
    const [copiedToClipboard, setCopiedToClipboard] = useState(false);

    const handleClick = () => {
        navigator.clipboard.writeText(text)
        setCopiedToClipboard(true)
        setTimeout(() => {
            setCopiedToClipboard(false)
        }, 1000)
    }

    return (
        <div
            style={styles.container}
            onClick={handleClick}
        >
            {text}
            <div style={styles.hint}>
                {copiedToClipboard ? "(copied)" : "(click to copy to clipboard)"}

            </div>

        </div>
    )
}


const styles = {
    container: {
        fontSize: "1.8rem",
        userSelect: "text",
        fontFamily: "LexendDeca",
        fontWeight: "300",
        display: "flex", flexDirection: "column", gap: ".25rem",
        cursor: "pointer",
    },
    hint: {
        fontSize: "1rem",
        color: "#ffffff60"
    }
}